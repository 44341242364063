import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef, EventEmitter,
  Input, OnDestroy,
  OnInit, Output,
  ViewChild,
} from '@angular/core';
import player, {
  AnimationConfigWithPath,
  BMCompleteEvent,
  BMCompleteLoopEvent, BMDestroyEvent,
} from 'lottie-web/build/player/lottie_svg';
import { AnimationItem } from 'lottie-web';
import { LottiePlayer } from 'lottie-web';
import { UntilDestroy } from 'ngx-unificator/decorator';
import { NgStyle } from '@angular/common';
import {PlatformService} from '../../services/platform.service';

@UntilDestroy()
@Component({
  selector: 'app-lottie',
  templateUrl: './lottie.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgStyle,
  ],
})
export class LottieComponent implements OnInit, AfterViewInit, OnDestroy {

  /**
   * Config properties
   */
  @Input() path: string;
  @Input() assetsPath: string;
  @Input() loop = true;
  @Input() autoplay = true;
  @Input() animationSpeed: number = 0.5;
  @Input() width: string = '100%';
  @Input() name: string | any;

  /**
   * Output events
   */
  @Output() public complete$: EventEmitter<BMCompleteEvent> = new EventEmitter<BMCompleteEvent>();
  @Output() public loopComplete$: EventEmitter<BMCompleteLoopEvent> = new EventEmitter<BMCompleteLoopEvent>();
  @Output() public destroy$: EventEmitter<BMDestroyEvent> = new EventEmitter<BMDestroyEvent>();
  @Output() public dataReady$: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Lottie default container
   */
  @ViewChild('lottieContainer') lottieContainer: ElementRef;

  /**
   * Native intersection observer
   * @private
   */
  private _intersectionObserver: IntersectionObserver;

  /**
   * Animation item
   * @private
   */
  private _animation: AnimationItem;

  constructor(
    private _platform: PlatformService,
  ) {
  }

  /**
   * Access to player SSR
   */
  get player(): LottiePlayer {
    if (this._platform.isBrowser) {
      return player;
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this._initializeIntersectionObserver();
    this._observeVisibility();
  }

  ngOnDestroy() {
    if (this._intersectionObserver) {
      this._intersectionObserver.disconnect();
    }
  }

  /**
   * Init observer and set entries
   * If entries exists load animation and render svg
   * @private
   */
  private _initializeIntersectionObserver(): void {
    this._intersectionObserver = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this._loadAnimation();
            this._intersectionObserver.unobserve(this.lottieContainer.nativeElement);
          }
        });
      },
      { threshold: 0.5 },
    );
  }

  /**
   * Observe visibility of element
   * @private
   */
  private _observeVisibility(): void {
    if (this._platform.isBrowser && this.lottieContainer.nativeElement) {
      this._intersectionObserver.observe(this.lottieContainer.nativeElement);
    }
  }

  /**
   * Load animation by path
   * @private
   */
  private _loadAnimation(): void {
    let options: AnimationConfigWithPath = {
      container: this.lottieContainer.nativeElement,
      path: this.path,
      assetsPath: this.assetsPath,
      renderer: 'svg',
      loop: this.loop,
      autoplay: this.autoplay,
    };
    options = {...options, name: this.name};
    this._animation = this.player.loadAnimation(options);
    this.player.setSpeed(this.animationSpeed);
    this._dispatchEvents();
  }

  private _dispatchEvents() {
    /**
     * Dispatched after completing the last frame
     */
    this._animation.addEventListener('complete', (event: BMCompleteEvent) => {
      this.complete$.next(event);
    });

    /**
     * Dispatched after completing frame loop
     */
    this._animation.addEventListener('loopComplete', (event: BMCompleteLoopEvent) => {
      this.loopComplete$.next(event);
    });

    /**
     * Dispatched in the ngOnDestroy hook of the service that manages lottie's events, it's useful for releasing resources
     */
    this._animation.addEventListener('destroy', (event: BMDestroyEvent) => {
      this.destroy$.next(event);
    });

    /**
     * Dispatched when all parts of the animation have been loaded
     */
    this._animation.addEventListener('data_ready', (event: undefined) => {
      this.dataReady$.next(true);
    });
  }
}
